import { Layout, Spin } from 'antd';
import React from 'react';
import { getShowSpinner } from 'src/ui/app/stateManagement/stateDataSelectors';
import { useTypedSelector } from 'src/ui/app/stateManagement/store';
const { Header, Content } = Layout;
import TopBar from 'src/ui/website/components/topbar';

export const TemplateLogin = ({ children: content, showSignup = true, showLogin = true, showControls = true }) => {
    const shouldShowSpinner = useTypedSelector(getShowSpinner);
    return (
        <Spin spinning={shouldShowSpinner}>
            <Layout style={{ height: '100vh' }}>
                <Header>
                    <TopBar showSignup={showSignup} showLogin={showLogin} showControls={showControls} />
                </Header>
                <Layout>
                    <Content>
                        <section className="hero  ">
                            <div className="hero-body">
                                <div className="container">
                                    <div className="columns is-centered">
                                        <div className="column is-8-tablet is-5-desktop is-5-widescreen">{content}</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Content>
                </Layout>
            </Layout>
        </Spin>
    );
};
export default TemplateLogin;
