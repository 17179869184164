import Head from 'next/head';
import LoginScreen from 'src/ui/app/screens/login';
const IndexPage = () => {
    return (
        <>
            <Head>
                <title>Login</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            </Head>
            <LoginScreen />
        </>
    );
};

export default IndexPage;
