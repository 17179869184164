import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'src/ui/common/Auth';
import { auth } from 'src/ui/common/firebaseClient';
import * as gtag from 'src/ui/common/gtag';
const Navbar = ({ showLogin = true, showSignup = true, showAppLink = true, showControls = true }) => (
    <nav
        className="navbar no-shadow  is-fixed-top "
        style={{ background: 'white' }}
        role="navigation"
        aria-label="main navigation"
    >
        <div className="container">
            <NavbarLogo></NavbarLogo>

            <div id="navbar-menu" className="navbar-menu is-static">
                <NavbarLeftContent />
                {showControls && (
                    <NavbarRightContent showLogin={showLogin} showSignup={showSignup} showAppLink={showAppLink} />
                )}
            </div>
        </div>
    </nav>
);

export const NavbarLogo = () => (
    <div className="navbar-brand">
        <a className="pt-2" href="/">
            <img src="/colorLogo3.png" alt="" width="40px" />
            {/* <img src="colorLogo3.png" alt="" /> */}
            Shaztra
            {/* <span className="tag is-primary is-light mx-1">αlpha</span> */}
        </a>
    </div>
);

// This is not being used
const MobileHamburger = () => {
    return (
        <a className="navbar-item is-hidden-mobile">
            <div id="menu-icon-wrapper" className="menu-icon-wrapper" style={{ visibility: 'visible' }}>
                <svg width="1000px" height="1000px">
                    <path
                        className="path1"
                        d="M 300 400 L 700 400 C 900 400 900 750 600 850 A 400 400 0 0 1 200 200 L 800 800"
                    ></path>
                    <path className="path2" d="M 300 500 L 700 500"></path>
                    <path
                        className="path3"
                        d="M 700 600 L 300 600 C 100 600 100 200 400 150 A 400 380 0 1 1 200 800 L 800 200"
                    ></path>
                </svg>
                <button id="menu-icon-trigger" className="menu-icon-trigger"></button>
            </div>
        </a>
    );
};

const NavbarLeftContent = () => (
    <div className="navbar-start">
        {/* any link or text on the left hand side */}
        <div className="navbar-item">{/*use navbar-item */}</div>
    </div>
);

export const NavbarRightContent = ({ showLogin = true, showSignup = true, showAppLink = true }) => {
    const { user, fetched } = useAuth();
    const router = useRouter();
    const [showSignInControls, setShowSignInControls] = useState<boolean>(true);
    useEffect(() => {
        if (user && user.uid && user.email) {
            //router.push("/app");
            setShowSignInControls(false);
        } else {
            setShowSignInControls(true);
        }
    }, [user]);

    const signOut = () => {
        auth.signOut().then(() => {
            router.push('/');
            setShowSignInControls(true);
        });
    };

    const gotoScreen = (path, action, label) => {
        gtag.event({ action, category: 'UserAction', label, value: 1 });
        gtag.trackGoal(label);
        router.push(path);
    };

    return (
        <div className="navbar-end">
            <div className="navbar-item">
                <div className="buttons">
                    {showSignInControls && showLogin && fetched && (
                        <a onClick={() => gotoScreen('/login', 'Login', 'gotoLogin')}>
                            <span className="button is-white">Log in</span>
                        </a>
                    )}
                    {showSignInControls && showSignup && (
                        <a onClick={() => gotoScreen('/signup', 'Signup', 'gotoSignup')}>
                            <span className="button is-white">Sign up</span>
                        </a>
                    )}
                    {!showSignInControls && !showAppLink && fetched && (
                        <>
                            <a onClick={signOut}>
                                <span className="button is-white">Logout</span>
                            </a>
                        </>
                    )}
                    {!showSignInControls && showAppLink && (
                        <>
                            <a href="/app">
                                <span className="button primary-btn">App</span>
                            </a>
                            <a onClick={signOut}>
                                <span className="button is-white">Logout</span>
                            </a>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
