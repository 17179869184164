import React, { useLayoutEffect, useState } from 'react';
import { Form, Input, Button, Space, message, Typography } from 'antd';
import { signIn } from 'src/ui/common/firebaseClient';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { waitAction, waitDoneAction } from '../stateManagement/sync/actionCreators';
import { useAuth } from 'src/ui/common/Auth';
import TemplateLogin from 'src/ui/app/components/templates/templateLogin';
import * as gtag from 'src/ui/common/gtag';
const { Text, Link, Title } = Typography;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};
const tailLayoutDesktop = {
    wrapperCol: { offset: 8, span: 16 }
};

const tailLayoutMobile = {
    wrapperCol: { offset: 1, span: 16 }
};

const LoginScreen = () => {
    const router = useRouter();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const [width, setWidth] = useState<number>(800);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useLayoutEffect(() => {
        setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    let isMobile: boolean = width <= 768;
    let tailLayout = tailLayoutDesktop;
    if (isMobile) {
        tailLayout = tailLayoutMobile;
    }

    const goToApp = (user) => {
        // window.location.pathname = '/app';
        router.push('/app');
        // let displayValue = user.email;
        // if (user.displayName) {
        //     const firstName = user.displayName.split(' ')[0];
        //     if (firstName) {
        //         displayValue = firstName;
        //     } else {
        //         displayValue = user.displayName;
        //     }
        // }
        // message.success(`Welcome ${displayValue}!`);
    };

    const onSubmit = async ({ email, password }) => {
        gtag.event({ action: 'Login', category: 'UserAction', label: 'loginSubmit', value: 1 });
        gtag.trackGoal('loginSubmit');
        dispatch(waitAction());
        const result = await signIn(email, password);
        dispatch(waitDoneAction());
        if (result.error) {
            message.error(result.error.message || 'Oops!: Unable to sign you in. Code:010.');
        }
    };

    const onFailedForm = (badValue, errorFields) => {
        errorFields.map((err) => {
            const errors = err.errors;
            errors.forEach((errMsg) => message.error(errMsg));
        });
    };

    useLayoutEffect(() => {
        if (user) {
            goToApp(user);
        }
    }, [user]);

    return (
        <TemplateLogin showLogin={false} showControls={!user}>
            {!user && (
                <Form
                    {...layout}
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                    onFinishFailed={({ values, errorFields }) => {
                        onFailedForm(values, errorFields);
                    }}
                >
                    <Form.Item>
                        <Title level={3} type="secondary">
                            Log in
                        </Title>
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please provide a valid email!' }]}
                    >
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please provide a valid password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item> */}

                    <Form.Item {...tailLayout}>
                        <Space>
                            <Button type="primary" htmlType="submit">
                                Login
                            </Button>
                            {/* <Button
                                htmlType="button"
                                onClick={() => {
                                    router.push('/signup');
                                }}
                            >
                                Sign up
                            </Button> */}
                            <Button
                                type="link"
                                htmlType="button"
                                onClick={() => {
                                    router.push('/forgot');
                                }}
                            >
                                Forgot Password
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Space>
                            <Text type="secondary">Don't have an account?</Text>
                            <Link
                                onClick={() => {
                                    router.push('/signup');
                                }}
                            >
                                Sign up
                            </Link>
                        </Space>
                    </Form.Item>
                </Form>
            )}
        </TemplateLogin>
    );
};

export default LoginScreen;
